// @flow
import type { Element, Node } from 'react'

import styles from './IconButton.module.css'

type IconButtonProps = {
  +onClick?: GenericFunction,
  +onMouseEnter?: GenericFunction,
  +onMouseLeave?: GenericFunction,
  +className?: string,
  +disabled?: boolean,
  +theme?: string,
  +children?: Node,
  +size?: string,
  +icon: Node,
  +tabIndex?: number,
  +type?: string,
  +active?: boolean,
}

export default function IconButton(props: IconButtonProps): Element<'button'> {
  const {
    theme = 'primary',
    icon = null,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className = '',
    disabled = false,
    active = false,
    children = null,
    tabIndex = 0,
    size = 'm',
    type = 'button',
  } = props

  return (
    <button
      {...{
        type,
        disabled,
        onClick,
        onMouseEnter,
        onMouseLeave,
        tabIndex,
      }}
      className={`
        ${styles.iconButton}
        ${className}
        ${theme ? styles[theme] : ''}
        ${active ? styles.active : ''}
        ${size ? styles['size_' + size] : ''}
      `}
    >
      {icon}
      {children}
    </button>
  )
}
