// @flow

import type { User } from 'flow-types/Trip'

import { useErrorHandler } from 'hooks'
import type { Node } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { USER_TYPE } from 'utils/const'
import { getCookie } from 'utils/cookie'

import { jsonGet } from 'utils/httpUtil'
import { resolveRestUrl } from 'utils/urlUtil'

const UserContext = createContext()

type ERUser = {
  canExport: boolean,
  companyId: number | null,
  email: string,
  newUser: boolean,
  noLoginUsersEnabled: boolean,
  notifications: Array<string>,
  packageInfo: {
    canAccountBeProlonged: boolean,
    canMakePayments: boolean,
    expired: boolean,
    expires: string,
    freeTrips: number,
    name: string,
    packageType: string,
  },
  pluginType: string | null,
  team: {
    approvalWorkflow: boolean,
    teamGroupPermission: boolean,
  } | null,
  user: User,
  userRole: string,
  isAuthenticated: boolean | null,
}

const initialState: ERUser = {
  canExport: false,
  companyId: null,
  email: '',
  newUser: false,
  noLoginUsersEnabled: false,
  notifications: [],
  packageInfo: {
    canAccountBeProlonged: false,
    canMakePayments: false,
    expired: false,
    expires: '',
    freeTrips: 0,
    name: '',
    packageType: '',
  },
  pluginType: null,
  team: {
    approvalWorkflow: false,
    teamGroupPermission: false,
  },
  user: {
    firstName: '',
    id: '',
    lastName: '',
    status: '',
  },
  userRole: '',
  isAuthenticated: true,
}

type UserProviderProps = {
  children: Node,
}

function UserProvider(props: UserProviderProps): Node {
  const { children } = props
  const handleError = useErrorHandler()
  const [user, setUser] = useState<ERUser>(initialState)
  const [isGuest, setIsGuest] = useState(false)
  const [isTrial, setIsTrial] = useState(false)
  const [isBasic, setIsBasic] = useState(false)
  const [showGuestModal, setShowGuestModal] = useState(false)

  const getLocalUserSettings = (name) => {
    if (user.user?.id) {
      const settingsFromStorage = localStorage.getItem('userSettings')
      const settings = settingsFromStorage
        ? JSON.parse(settingsFromStorage)
        : null

      return name &&
        settings &&
        settings[user.user.id] &&
        settings[user.user.id][name]
        ? settings[user.user.id][name]
        : null
    }
  }

  // LOCALSTORE MIGRATION FOR CURRENT USERS
  // SHOULD BE REMOVED @ 01.03.22
  useEffect(() => {
    const migrateLocalStorage = () => {
      const oldVisibility = localStorage.getItem(
        'EinfachReisekostenFilterSettings'
      )
      const oldSort = localStorage.getItem('EinfachReisekostenListingSort')

      if (oldVisibility) {
        setLocalUserSettings('filterVisibility', JSON.parse(oldVisibility))
        localStorage.removeItem('EinfachReisekostenFilterSettings')
      }

      if (oldSort) {
        setLocalUserSettings('listingSort', oldSort)
        localStorage.removeItem('EinfachReisekostenListingSort')
      }
    }

    setIsGuest(USER_TYPE.GUEST === user.userRole)
    setIsTrial(USER_TYPE.TRIAL_USER === user.userRole)
    setIsBasic(USER_TYPE.BASIC_USER === user.userRole)

    if (user.user?.id) {
      migrateLocalStorage()
    }
    // eslint-disable-next-line
  }, [user])

  const setLocalUserSettings = (name: string, value: string) => {
    if (user.user?.id) {
      const settingsFromStorage = localStorage.getItem('userSettings')
      const settings = settingsFromStorage
        ? JSON.parse(settingsFromStorage)
        : {}

      localStorage.setItem(
        'userSettings',
        JSON.stringify({
          ...settings,
          [user.user.id]: {
            ...(settings[user.user.id] ?? {}),
            [name]: value,
          },
        })
      )
    }
  }

  const refreshUserContext = useCallback(async () => {
    try {
      const { response } = await jsonGet(
        resolveRestUrl('user/public/user-context'),
        true
      )
      if (!response) {
        // 200 but no response
        if (!window.location.pathname.startsWith('/public')) {
          window.location =
            '/public/login?redirect=' +
            encodeURIComponent(
              window.location.pathname + window.location.search
            )
        }
      } else {
        setUser({
          ...response,
          isAuthenticated: true,
        })
        if (
          !window.location.pathname.startsWith('/public') &&
          response.userRole === 'GUEST'
        ) {
          const cookieId = getCookie('guest')
          const path = '/trip/' + (cookieId || '')
          if (window.location.pathname !== path) {
            window.location.pathname = path
          }
        }
      }
    } catch (error) {
      setUser(initialState)
      handleError(error)
    }
  }, [handleError])

  useEffect(() => {
    refreshUserContext()
  }, [refreshUserContext])

  const isProd = window.location.host === 'app.einfach-reisekosten.de'

  return (
    <UserContext.Provider
      value={{
        user,
        isProd,
        refreshUserContext,
        showGuestModal,
        setShowGuestModal,
        getLocalUserSettings,
        setLocalUserSettings,
        isGuest,
        isTrial,
        isBasic,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUserContext(): {
  user: ERUser,
  isProd: boolean,
  refreshUserContext: GenericFunction,
  showGuestModal: boolean,
  setShowGuestModal: (((boolean) => boolean) | boolean) => void,
  getLocalUserSettings: GenericFunction,
  setLocalUserSettings: GenericFunction,
  isGuest: boolean,
  isTrial: boolean,
  isBasic: boolean,
} {
  const ctx = useContext(UserContext)

  if (!ctx) {
    throw new Error('useUserContext must be used within UserProvider')
  }

  return ctx
}

export { useUserContext, UserProvider }
