// @flow
import { useUserContext } from 'context/user'
import type { Trip } from 'flow-types/Trip'
import { TRIP_APPROVAL_STATUS, TRIP_EXPORT_STATUS } from 'utils/const'

export type ApprovalOption = {
  approval: string,
  primary: boolean,
}

export type ApprovalOptions = Array<ApprovalOption>

export function usePermissionHandler(): {
  canEditTrip: (trip: Trip, checkExportedStatus?: boolean) => boolean,
  getPrimaryApprovalOptions: (trip: Trip) => ApprovalOptions,
  getSecondaryApprovalOptions: (trip: Trip) => ApprovalOptions,
} {
  const { user } = useUserContext()

  function isExportBlocked(trip, checkExportedStatus = true) {
    return (
      trip.export != null &&
      ((checkExportedStatus && trip.export.status === TRIP_EXPORT_STATUS.EXPORTED) ||
        trip.export.status === TRIP_EXPORT_STATUS.EXPORTING)
    )
  }

  const canEditTrip = (trip, checkExportedStatus = true): boolean => {
    const approval =
      trip.approval !== null ? trip.approval.status : TRIP_APPROVAL_STATUS.DRAFT
    if (
      user.team?.approvalWorkflow &&
      user.userRole === 'BUSINESS_USER' &&
      (approval === TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL ||
        approval === TRIP_APPROVAL_STATUS.APPROVED)
    ) {
      return false
    }
    if (checkExportedStatus && isExportBlocked(trip, checkExportedStatus)) {
      return false
    }

    return !(user.userRole === 'BUSINESS_USER' &&
      approval === TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL);

  }

  const getPrimaryApprovalOptions = (trip): ApprovalOptions => {
    return getApprovalOptions(trip).filter((item) => item.primary)
  }

  const getSecondaryApprovalOptions = (trip): ApprovalOptions => {
    return getApprovalOptions(trip).filter((item) => !item.primary)
  }

  const getApprovalOptions = (trip): ApprovalOptions => {
    let result = []

    if (!user.team?.approvalWorkflow || isExportBlocked(trip)) {
      return result
    }

    const approval =
      trip.approval !== null ? trip.approval.status : TRIP_APPROVAL_STATUS.DRAFT
    if (user.userRole === 'BUSINESS_USER') {
      switch (approval) {
        case TRIP_APPROVAL_STATUS.REJECTED:
        case TRIP_APPROVAL_STATUS.DRAFT:
          result.push({
            approval: TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL,
            primary: true,
          })
          break
        default:
          break
      }
    } else if (
      user.userRole === 'BUSINESS_ADMIN' ||
      user.userRole === 'BUSINESS_MANAGER'
    ) {
      const isOwnTrip = trip.version === 0 || trip.user?.id === user.user.id

      switch (approval) {
        case TRIP_APPROVAL_STATUS.REJECTED:
          result.push({ approval: TRIP_APPROVAL_STATUS.DRAFT, primary: false })
          result.push({
            approval: TRIP_APPROVAL_STATUS.APPROVED,
            primary: false,
          })
          break
        case TRIP_APPROVAL_STATUS.DRAFT:
          result.push({
            approval: TRIP_APPROVAL_STATUS.APPROVED,
            primary: isOwnTrip,
          })
          break
        case TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL:
          result.push({
            approval: TRIP_APPROVAL_STATUS.APPROVED,
            primary: true,
          })
          if (!isOwnTrip) {
            result.push({
              approval: TRIP_APPROVAL_STATUS.REJECTED,
              primary: true,
            })
          } else {
            result.push({
              approval: TRIP_APPROVAL_STATUS.DRAFT,
              primary: false,
            })
          }
          break
        case TRIP_APPROVAL_STATUS.APPROVED:
          result.push({ approval: TRIP_APPROVAL_STATUS.DRAFT, primary: false })
          if (!isOwnTrip) {
            result.push({
              approval: TRIP_APPROVAL_STATUS.REJECTED,
              primary: false,
            })
          }
          break
        default:
          break
      }
    }
    return result
  }

  return {
    canEditTrip,
    getPrimaryApprovalOptions,
    getSecondaryApprovalOptions,
  }
}
