// @flow

import type { Node } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import ToastNotifications from 'components/base/ToastNotifications'

const ToastContext = createContext()

type ToastProviderProps = {
  children: Node
}

export type Toast = {
  id?: string,
  type: string,
  message: string,
  timeout?: number,
  linkText?: string,
  linkOnClick?: GenericFunction,
}

export type Toasts = Array<Toast>

function ToastProvider(props : ToastProviderProps) : Node{
  const { children } = props
  const [toasts, setToasts] = useState<Array<Toast>>([])

  const addToastNotice = useCallback((newToast : Toast ) => {
    setToasts((toasts) => [{ ...newToast, id: uuidv4() }, ...toasts])
  }, [])

  const handleRemove = (removeId) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== removeId))
  }

  return (
    <ToastContext.Provider
      value={{
        addToastNotice,
      }}
    >
      {children}
      <ToastNotifications toasts={toasts} onRemove={handleRemove} />
    </ToastContext.Provider>
  )
}

function useToastContext() : {
  addToastNotice: GenericFunction
} {
  const ctx = useContext(ToastContext)

  if (!ctx) {
    throw new Error('useToastContext must be used within ToastProvider')
  }

  return ctx
}

export { useToastContext, ToastProvider }
