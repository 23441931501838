export function jsonGet(url, getFullresponse) {
  return jsonRequest(url, 'GET', null, null, getFullresponse)
}

export function jsonPost(url, data, getFullresponse) {
  return jsonRequest(url, 'POST', data, null, getFullresponse)
}

export function jsonPut(url, data) {
  return jsonRequest(url, 'PUT', data)
}

export function jsonDelete(url) {
  return jsonRequest(url, 'DELETE')
}

export function rawPost(url, data, headers, responseType) {
  return rawRequest(url, 'POST', data, headers, responseType)
}

export function rawGet(url, data, headers, responseType) {
  return rawRequest(url, 'GET', data, headers, responseType)
}

async function jsonRequest(url, method, data, headers = {}, getFullresponse) {
  const x = await rawRequest(url, method, JSON.stringify(data), {
    ...headers,
    'Content-Type': 'application/json; charset=UTF-8',
  })
  if (getFullresponse) {
    return { ...x, response: x.response ? JSON.parse(x.response) : x.response }
  } else {
    return x.response ? JSON.parse(x.response) : x.response
  }
}

function rawRequest(url, method, data, headers, responseType) {
  const xhr = new XMLHttpRequest()
  if (window.location.host.indexOf(':3000') !== -1) {
    // workaround for local node server
    xhr.withCredentials = true
  }
  xhr.open(method || 'GET', url)
  for (const key in headers || {}) {
    xhr.setRequestHeader(key, headers[key])
  }

  xhr.responseType = responseType || 'text'

  // Return it as a Promise
  const promise = new Promise(function (resolve, reject) {
    // Setup our listener to process compeleted requests
    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== 4) {
        return
      }

      // Process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({
          status: xhr.status,
          response: responseType === 'blob' ? xhr.response : xhr.responseText,
          statusText: xhr.statusText,
        })
      } else {
        reject(xhr)
      }
    }
  })
  xhr.send(data)
  return promise
}
