// @flow
import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

import { useUserContext } from 'context/user'

import AppProviders from 'app/Providers'
import * as serviceWorker from 'serviceWorker'

import 'assets/css/bootstrap-reboot.min.css'
/*
For test reasons currently disabled and moved to index.html
import 'assets/css/fonts/lato/index.css'
import 'assets/css/fonts/material_icons/index.css'
*/
import 'index.css'

const UnauthenticatedApp = lazy(() => import('app/Unauthenticated'))
const AuthenticatedApp = lazy(() => import('app/Authenticated'))

const App = () => {
  const {
    user: { isAuthenticated },
  } = useUserContext()

  return (
    <>
      <Suspense fallback={null}>
        {isAuthenticated && <AuthenticatedApp />}
      </Suspense>
      <Suspense fallback={null}>
        <UnauthenticatedApp />
      </Suspense>
    </>
  )
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
)

serviceWorker.register()
