import { useState } from 'react'
import { useDragEvents } from 'beautiful-react-hooks'

export const useDropZone = (targetRef) => {
  const { onDrop, onDragOver, onDragLeave } = useDragEvents(targetRef)
  const [isDragItemOver, setIsDragItemOver] = useState(false)

  onDragOver((event) => {
    event.preventDefault()
    setIsDragItemOver(true)
  })

  onDragLeave(() => {
    setIsDragItemOver(false)
  })

  return {
    onDrop,
    isDragItemOver,
    setIsDragItemOver,
  }
}
