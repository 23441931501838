// @flow
import type { Node } from 'react'
import { createContext, useContext } from 'react'
import { useUserContext } from 'context/user'

const SnippetContext = createContext()

type SnippetProviderProps = {
  children: Node
}

function SnippetProvider(props : SnippetProviderProps) : Node {
  const { children } = props
  const { isProd, user } = useUserContext()

  const openCoviewSupport = () => {
    if (!window['coview']) {
      console.log('coview is not defined')
    } else {
      window['coview']('openWidget')
    }
  }

  const injectCoview = () => {
    const scriptUrl = 'https://cdn.coview.com/coview.js'
    if (
      !isProd ||
      document.querySelectorAll('[src="' + scriptUrl + '"]').length
    ) {
      return
    }

    window['coview'] =
      window['coview'] ||
      function () {
        ;(window['coview'].a = window['coview'].a || []).push(arguments)
      }

    let script = document.createElement('script')
    script.src = scriptUrl
    script.async = true
    if(document.head) {
      document.head.append(script)
    }
    window.addEventListener('error', function (err) {
      window['coview']('error', err)
    })
    window.addEventListener('message', function (msg) {
      window['coview']('message', msg)
    })
    window['coview']('set', {
      projectKey: 'TDEXx1WLV-8',
      publicPage: false,
      chat: { showButton: false },
      hostUrl: 'https://app.coview.com',
      user: {
        id: user?.user.id,
        email: user?.email,
      },
    })
  }

  return (
    <SnippetContext.Provider
      value={{
        openCoviewSupport,
        injectCoview,
      }}
    >
      {children}
    </SnippetContext.Provider>
  )
}

function useSnippetContext() : {
  openCoviewSupport: GenericFunction,
  injectCoview: GenericFunction
} {
  const ctx = useContext(SnippetContext)

  if (!ctx) {
    throw new Error('useSnippetContext must be used within SnippetProvider')
  }

  return ctx
}

export { useSnippetContext, SnippetProvider }
