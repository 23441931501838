// @flow
import type { Element, Node } from 'react'
import { StrictMode } from 'react'

import { ToastProvider } from 'context/toast'
import { UserProvider } from 'context/user'
import { SnippetProvider } from 'context/snippets'

type Props = {
  +children: Node,
}

export default function AppProviders({
  children,
}: Props): Element<typeof StrictMode> {
  return (
    <StrictMode>
      <ToastProvider>
        <UserProvider>
          <SnippetProvider>{children}</SnippetProvider>
        </UserProvider>
      </ToastProvider>
    </StrictMode>
  )
}
