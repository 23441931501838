import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined'

import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import EuroIcon from '@material-ui/icons/Euro'

import FlagIcon from '@material-ui/icons/Flag'
import FlightIcon from '@material-ui/icons/Flight'
import HomeIcon from '@material-ui/icons/Home'
import HotelIcon from '@material-ui/icons/Hotel'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'

import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import LocalParkingIcon from '@material-ui/icons/LocalParking'
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import MoreIcon from '@material-ui/icons/More'

import MoveToInboxOutlinedIcon from '@material-ui/icons/MoveToInboxOutlined'
import NearMeIcon from '@material-ui/icons/NearMe'
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined'
import PhoneIcon from '@material-ui/icons/Phone'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined'
import TrainIcon from '@material-ui/icons/Train'
import WorkIcon from '@material-ui/icons/Work'

import iconBillomat from 'assets/images/apps/small/billomat.png'
import iconDebitoor from 'assets/images/apps/small/debitoor.png'
import iconLexoffice from 'assets/images/apps/small/lexoffice.png'
import iconNoPlugin from 'assets/images/apps/small/locked.png'
import iconSevdesk from 'assets/images/apps/small/sevdesk.png'

import styles from './const.module.css'

export const CONVERSION_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
}

export const TEXT_SNIPPETS = {
  VALIDATION_TARIFFS_FOR_EMPLOYER_NOTICE:
    'Individuelle Pauschalen können ausschließlich von Ihrem Arbeitgeber erstattet werden. Möchten Sie die Abrechnung Ihrer Reisekosten auf den',
  VALIDATION_TARIFFS_FOR_EMPLOYER_LINK: 'Arbeitgeber-Modus umstellen?',
  TARIFFS_DROPDOWN_EDIT: 'Eigene Pauschalen',
  TARIFFS_DROPDOWN_DEFAULT: 'Gesetzliche Pauschale',
}

export const EXPENSE_PAYMENT_TYPES = {
  PRIVATE: 'PRIVATE',
  COMPANY: 'COMPANY', //  Firma
  COMPANY_CREDIT_CARD: 'COMPANY_CREDIT_CARD', // Kreditkarte
  COMPANY_DEBIT_CARD: 'COMPANY_DEBIT_CARD', // EC-Karte
  COMPANY_CASH: 'COMPANY_CASH', // Bar
  COMPANY_TRANSFER: 'COMPANY_TRANSFER', // Überweisung
  COMPANY_DIRECT_DEBIT: 'COMPANY_DIRECT_DEBIT', // Lastschrift
}

export const USER_TYPE = {
  GUEST: 'GUEST',
  TRIAL_USER: 'TRIAL_USER',
  BASIC_USER: 'BASIC_USER',
  REGULAR_USER: 'REGULAR_USER',
  BUSINESS_NO_LOGIN_USER: 'BUSINESS_NO_LOGIN_USER',
  BUSINESS_USER: 'BUSINESS_USER',
  BUSINESS_ADMIN: 'BUSINESS_ADMIN',
  BUSINESS_MANAGER: 'BUSINESS_MANAGER',
}

export const EDIT_TYPE = {
  EXPENSE: 'EXPENSE',
  MEAL: 'MEAL',
  ROUTE: 'ROUTE',
}

export const PLUGINS = {
  LEXOFFICE: 'LEXOFFICE',
  DEBITOOR: 'DEBITOOR',
  SEVDESK: 'SEVDESK',
  BILLOMAT: 'BILLOMAT',
  NONE: 'NONE',
}

export const PLUGINS_PROPS = {
  [PLUGINS.LEXOFFICE]: {
    title: 'lexoffice',
    icon: iconLexoffice,
  },
  [PLUGINS.DEBITOOR]: {
    title: 'Debitoor',
    icon: iconDebitoor,
  },
  [PLUGINS.SEVDESK]: {
    title: 'sevdesk',
    icon: iconSevdesk,
  },
  [PLUGINS.BILLOMAT]: {
    title: 'Billomat',
    icon: iconBillomat,
  },
  [PLUGINS.NONE]: {
    title: '',
    icon: iconNoPlugin,
  },
}

export const TRIP_EXPORT_STATUS = {
  NONE: 'NONE',
  EXPORTING: 'EXPORTING',
  EXPORTED: 'EXPORTED',
  FAILED: 'FAILED',
  RETRY: 'RETRY',
}

export const TRIP_EXPORT_STATUS_PROPS = {
  [TRIP_EXPORT_STATUS.NONE]: {
    title: 'Nicht exportiert',
    icon: <CloudOffOutlinedIcon className={styles[TRIP_EXPORT_STATUS.NONE]} />,
  },
  [TRIP_EXPORT_STATUS.EXPORTING]: {
    title: 'Wird exportiert',
    icon: <SyncOutlinedIcon className={styles[TRIP_EXPORT_STATUS.EXPORTING]} />,
  },
  [TRIP_EXPORT_STATUS.EXPORTED]: {
    title: 'Exportiert',
    icon: (
      <CloudDoneOutlinedIcon className={styles[TRIP_EXPORT_STATUS.EXPORTED]} />
    ),
  },
  [TRIP_EXPORT_STATUS.FAILED]: {
    title: 'Export fehlgeschlagen',
    icon: (
      <CloudOffOutlinedIcon className={styles[TRIP_EXPORT_STATUS.FAILED]} />
    ),
  },
  [TRIP_EXPORT_STATUS.RETRY]: {
    title: 'Reise exportieren',
    icon: (
      <CloudUploadOutlinedIcon className={styles[TRIP_EXPORT_STATUS.RETRY]} />
    ),
  },
}

export const TRIP_APPROVAL_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DRAFT: 'DRAFT',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  UPDATING: 'UPDATING',
}

export const TRIP_APPROVAL_STATUS_PROPS = {
  [TRIP_APPROVAL_STATUS.APPROVED]: {
    title: 'Genehmigt',
    icon: (
      <CheckCircleOutlineOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.APPROVED]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.REJECTED]: {
    title: 'Zurückgewiesen',
    icon: (
      <NotInterestedOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.REJECTED]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.DRAFT]: {
    title: 'Nicht eingereicht (Entwurf)',
    icon: (
      <InsertDriveFileOutlined className={styles[TRIP_APPROVAL_STATUS.DRAFT]} />
    ),
  },
  [TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL]: {
    title: 'Eingereicht',
    icon: (
      <MoveToInboxOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.WAITING_FOR_APPROVAL]}
      />
    ),
  },
  [TRIP_APPROVAL_STATUS.UPDATING]: {
    title: 'Wird aktualisiert',
    icon: (
      <SyncOutlinedIcon
        className={styles[TRIP_APPROVAL_STATUS.SYNCHRONIZING]}
      />
    ),
  },
}

export const PLUGIN_ERROR_CODE = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  DOWNLOAD_PDF_ERROR: 'DOWNLOAD_PDF_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  UNKNOWN_VALIDATION_ERROR: 'UNKNOWN_VALIDATION_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  MISSING_VOUCHER_ITEMS: 'MISSING_VOUCHER_ITEMS',
  POSITIVE_EXPENSE_COSTS_REQUIRED: 'POSITIVE_EXPENSE_COSTS_REQUIRED',
  POSITIVE_TOTAL_COSTS_REQUIRED: 'POSITIVE_TOTAL_COSTS_REQUIRED',
  MAX_FILE_SIZE_EXCEEDED: 'MAX_FILE_SIZE_EXCEEDED',
  SUPPLIER_NOT_FOUND: 'SUPPLIER_NOT_FOUND',
  LEXOFFICE_ACCOUNT_NUMBER_CONFLICT: 'LEXOFFICE_ACCOUNT_NUMBER_CONFLICT',
  INVALID_VAT_RATE: 'INVALID_VAT_RATE',
  SMALL_BUSINESS_RULES_VIOLATED: 'SMALL_BUSINESS_RULES_VIOLATED',
}
export const PLUGIN_ERROR_CODE_MESSAGES = {
  [PLUGIN_ERROR_CODE.UNAUTHORIZED]: {
    message: (
      <>
        Die Zugriffsberechtigung für die Partner App ist abgelaufen. Bitte{' '}
        <a href='/settings/apps'> aktualisieren Sie die Verknüpfung</a>
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.DOWNLOAD_PDF_ERROR]: {
    message: (
      <>Fehler beim erstellen des PDFs. Bitte versuchen Sie es erneut.</>
    ),
  },
  [PLUGIN_ERROR_CODE.INTERNAL_SERVER_ERROR]: {
    message: <>Es is ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</>,
  },
  [PLUGIN_ERROR_CODE.UNKNOWN_VALIDATION_ERROR]: {
    message: (
      <>
        Unbekannter Validierungsfehler von der Partner App. Bitte wenden Sie
        sich an den Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.UNKNOWN_ERROR]: {
    message: (
      <>
        Unbekannter Fehler beim Exportieren. Bitte wenden Sie sich an den
        Support.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.MISSING_VOUCHER_ITEMS]: {
    message: (
      <>Abrechnungen ohne Posten werden von der Partner App nicht akzeptiert</>
    ),
  },
  [PLUGIN_ERROR_CODE.POSITIVE_EXPENSE_COSTS_REQUIRED]: {
    message: (
      <>
        Abrechnungen mit negativen Kosten werden von der Partner App nicht
        akzeptiert
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.POSITIVE_TOTAL_COSTS_REQUIRED]: {
    message: (
      <>
        Abrechnungen mit negativen Gesamtkosten werden von der Partner App nicht
        akzeptiert
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.MAX_FILE_SIZE_EXCEEDED]: {
    message: (
      <>
        Die Dateigröße überschreitet die zulässige Maximalgröße der Partner App.
        Bitte verkleinern Sie die hochgeladenen Belegdateien.
      </>
    ),
  },

  [PLUGIN_ERROR_CODE.SUPPLIER_NOT_FOUND]: {
    message: (
      <>
        Es wurde kein Lieferant ausgewählt. Bitte tragen Sie einen{' '}
        <a href='/settings/apps'>Standardlieferanten in den Einstellungen</a>{' '}
        ein.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.LEXOFFICE_ACCOUNT_NUMBER_CONFLICT]: {
    message: (
      <>
        Es scheint einen Konflikt mit den Kundennummernkreisen Ihrer
        lexoffice-Kontakte zu geben. Überprüfen Sie Ihre{' '}
        <a href='https://app.lexoffice.de/settings/#/number-ranges'>
          Einstellungen
        </a>{' '}
        oder kontaktieren Sie den lexoffice Support
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.INVALID_VAT_RATE]: {
    message: (
      <>
        Die Abrechnung wurde aufgrund fehlerhafter MwSt-Angaben abgelehnt. Bitte
        überprüfen Sie Ihre eingetragenen MwSt-Sätze.
      </>
    ),
  },
  [PLUGIN_ERROR_CODE.SMALL_BUSINESS_RULES_VIOLATED]: {
    message: (
      <>
        Die Abrechnung wurde aufgrund der Kleinunternehmerregelung abgelehnt.
        Bitte stellen Sie sicher, dass Sie in Ihren{' '}
        <a href='/settings/apps'>Einstellungen</a> die Kleinunternehmerregelung
        aktiviert haben.
      </>
    ),
  },
}

export const EXPENSE_PAYMENT_OPTIONS_COMPENSATION = [
  {
    title: 'Firma',
    value: EXPENSE_PAYMENT_TYPES.COMPANY,
    isSplitter: true,
    splitterTitle: 'Von Firma gezahlt',
  },
  {
    title: 'Firma - Kreditkarte',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_CREDIT_CARD,
  },
  {
    title: 'Firma - EC-Karte',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_DEBIT_CARD,
  },
  {
    title: 'Firma - Barzahlung',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_CASH,
  },
  {
    title: 'Firma - Überweisung',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_TRANSFER,
  },
  {
    title: 'Firma - Lastschrift',
    value: EXPENSE_PAYMENT_TYPES.COMPANY_DIRECT_DEBIT,
  },
]

export const EXPENSE_PAYMENT_OPTIONS = [
  {
    title: 'Privat Auslage',
    value: EXPENSE_PAYMENT_TYPES.PRIVATE,
    isSplitter: true,
    splitterTitle: 'Privat gezahlt',
  },
  ...EXPENSE_PAYMENT_OPTIONS_COMPENSATION,
]

export const ROUTE_ITEM_TYPES = {
  start: {
    icon: <HomeIcon />,
    title: 'Reisebeginn',
    dateLabel: 'Reisebeginn',
    datePlaceholder: 'Abfahrtsdatum',
  },
  lap: {
    icon: <LocationOnIcon />,
    title: 'Etappe',
    dateLabel: 'Ankunft',
    datePlaceholder: 'Ankunftsdatum',
  },
  end: {
    icon: <FlagIcon />,
    title: 'Reiseende',
    dateLabel: 'Reiseende',
    datePlaceholder: 'Ankunftsdatum',
  },
  pause: {
    icon: <HourglassFullIcon />,
    title: 'Unterbrechung',
    dateLabel: '',
    datePlaceholder: '',
  },
  destination: {
    icon: <LocationOnIcon />,
    title: 'Reiseziel',
    dateLabel: 'Ankunft Reiseziel',
    datePlaceholder: 'Ankunftsdatum (optional)',
  },
}

export const EXPENSE_TYPES = {
  PRIVATE_RIDE: {
    icon: <NearMeIcon />,
    title: 'Privatfahrzeug',
  },
  DETAILED_ACCOMODATION: {
    icon: <HotelIcon />,
    title: 'Übernachtung',
  },
  FLAT_ACCOMODATION: {
    icon: <HotelIcon />,
    title: 'Übernachtungspauschale',
  },
  FUEL: {
    icon: <LocalGasStationIcon />,
    title: 'Tankstelle',
  },
  ADD_RIDE_COSTS: {
    icon: <EuroIcon />,
    title: 'Fahrtnebenkosten',
  },
  PARKING: {
    icon: <LocalParkingIcon />,
    title: 'Parkgebühren',
  },
  SERVICE: {
    icon: <RestaurantMenuIcon />,
    title: 'Bewirtung',
  },
  CATERING_OTHER: {
    icon: <RestaurantMenuIcon />,
    title: 'Verpflegungskosten - Sonstige',
  },
  LUGGAGE: {
    icon: <WorkIcon />,
    title: 'Gepäck',
  },
  COMMUNICATION: {
    icon: <PhoneIcon />,
    title: 'Kommunikation',
  },
  EXTRA_OTHER: {
    icon: <MoreIcon />,
    title: 'Reisenebenkosten - Sonstige ',
  },
  TRAIN: {
    icon: <TrainIcon />,
    title: 'Bahnticket',
  },
  PLANE: {
    icon: <FlightIcon />,
    title: 'Flugticket',
  },
  TAXI: {
    icon: <LocalTaxiIcon />,
    title: 'Taxifahrt',
  },
  RENTAL: {
    icon: <DirectionsCarIcon />,
    title: 'Mietwagen',
  },
  RIDE_OTHER: {
    icon: <MoreIcon />,
    title: 'Fahrtkosten - Sonstige',
  },
  REFUND: {
    icon: <CreditCardIcon />,
    title: 'Erstattung',
  },
  PREPAYMENT: {
    icon: <CreditCardIcon />,
    title: 'Vorauszahlung',
  },
  COMPENSATION_OTHER: {
    icon: <MoreIcon />,
    title: 'Sonstige Zahlungen',
  },
}
